import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from "@reach/router"

// Components
import {
    Toast
} from 'components';

import * as actionsReports from "actions/reports";
import * as actionsValidations from "actions/validations";
import * as actionsNotifications from "actions/notifications";

// Assets
import 'font-awesome/css/font-awesome.min.css';
import styles from "./styles.module.css";

export default function Footer() {

    const dispatch = useDispatch()

    const { notifications } = useSelector(state => state.notifications);

    const checkReportSubmissionStatus = () => {
        if(sessionStorage.getItem('reportSubmissionIds')) {
            const reportSubmissionIds = sessionStorage.getItem('reportSubmissionIds');
            const submissionIds = JSON.parse(reportSubmissionIds)

            if(submissionIds.length) {
                submissionIds.map(submissionId => {
                    dispatch(actionsReports.checkReportSubmissionStatus(submissionId, submissionIds));
                })
            }
        }
    }

    const checkValidationSubmissionStatus = () => {
        if(sessionStorage.getItem('validationActionIds')) {
            const validationActionIds = sessionStorage.getItem('validationActionIds');
            const submissionIds = JSON.parse(validationActionIds)

            if(submissionIds.length) {
                submissionIds.map(submissionId => {
                    dispatch(actionsValidations.checkValidationSubmissionStatus(submissionId, submissionIds));
                })
            }
        }        
    }

    const getNotifications = () => {
        dispatch(actionsNotifications.getNotifications());
    }

    const selectCategory = (category) => {
        navigate(`/logs/${category}`)
    };

    useEffect(() => {
        const reportSubmissionInterval = setInterval(checkReportSubmissionStatus, 30000);
        const validationSubmissionInterval = setInterval(checkValidationSubmissionStatus, 30000);
        const notificationsInterval = setInterval(getNotifications, 10000);

        return () => {
          clearInterval(reportSubmissionInterval);
          clearInterval(validationSubmissionInterval);
          clearInterval(notificationsInterval);
        };
    }, []);

    return (
        <>
            <div className={ styles.component }>
                2021 &copy; RegReportingHub
            </div>
            <div className={ styles.footer }> {
                notifications.map((notification) => (
                    <Toast
                        selectCategory={() => selectCategory(notification.description)}
                        successStatus={notification.successStatus}
                        description={notification.description}
                        guid={notification.notification_guid}
                        message={notification.message}
                        key={notification.notification_guid} 
                    />
                ))
            }
            </div>
        </>
    );
}
